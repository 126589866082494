<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
    ></v-skeleton-loader>
    <div v-else>
      <v-row
        class="mb-5"
      >
        <v-col
          sm="6"
          md="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              <div>
                <h2 class="font-weight-semibold mb-1">
                  {{ users.length }}
                </h2>
                <span>utilisateur(s)</span>
              </div>
              <v-avatar>
                <v-icon
                  color="primary"
                >
                  {{ icons.mdiAccount }}
                </v-icon>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="6"
          md="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              <div>
                <h2 class="font-weight-semibold mb-1">
                  {{ statusList.length }}
                </h2>
                <span>rôles</span>
              </div>
              <v-avatar>
                <v-icon
                  color="info"
                >
                  {{ icons.mdiAccountDetails }}
                </v-icon>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="6"
          md="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              Coming soon
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="6"
          md="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              Coming soon
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>
            Utilisateurs
          </h4>
          <v-icon
            top
            right
          >
            {{ icons.mdiAccountMultiple }}
          </v-icon>
        </v-card-title>
        <v-row class="px-2 ma-0">
          <v-col
            sm="4"
            cols="12"
          >
            <v-select
              v-model="roleFilter"
              :items="statusList"
              multiple
              label="Filtrer par rôle"
              item-text="text"
              item-value="statusName"
              clearable
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            sm="4"
            cols="12"
          >
            <v-select
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            sm="4"
            cols="12"
          >
            <v-select
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-text-field
            v-model="search"
            label="Rechercher"
            clearable
            dense
            outlined
          ></v-text-field>
          <v-spacer></v-spacer>
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="$router.push({name:'user-new'})"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              Ajouter utilisateur
            </v-btn>
          </div>
        </v-card-text>
        <v-data-table
          :items="userFilter"
          :headers="headers"
          :search="search"
        >
          <!-- UTILISATEUR -->
          <template #[`item.displayName`]="{item}">
            <div class="d-flex align-center">
              <UserAvatar
                size="34px"
                color="primary"
                :user="item"
              ></UserAvatar>
              <p class="ml-4 pt-4">
                {{ item.displayName }}
              </p>
            </div>
          </template>
          <!-- RÔLE -->
          <template #[`item.status`]="{item}">
            <UserRoleChip :role="item.status"></UserRoleChip>
          </template>
          <!-- END RÔLE -->
          <!-- CREATED AT -->
          <template #[`item.created_at`]="{item}">
            {{ new Date(item.created_at) | date('dd MMMM yyyy') }}
          </template>
          <!-- END CREATED AT -->
          <!-- ACTIONS -->
          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(action, i) in actionMenu"
                  :key="i"
                  link
                >
                  <v-list-item-title @click="doAction(action.action, item)">
                    {{ action.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template v-slot:default="dialogslot">
        <v-card>
          <v-toolbar
            color="primary"
          >
            <span class="white--text">
              Modifier un utilsateur
            </span>
          </v-toolbar>
          <!-- DELETE -->
          <v-card-text v-if="selectedAction === 'delete'">
            <p class="my-3">
              Souhaitez-vous supprimer
              <span class="ml-4">
                <UserAvatar
                  size="34px"
                  color="primary"
                  :user="selectedUser"
                ></UserAvatar>
                <b>
                  {{ selectedUser.displayName }}
                </b>
              </span>
            </p>
          </v-card-text>
          <!-- END DELETE -->
          <!-- ROLE EDIT -->
          <v-card-text v-if="selectedAction === 'updateRole'">
            <p class="my-3">
              Modifier le rôle de <span class="ml-4">
                <UserAvatar
                  size="34px"
                  color="primary"
                  :user="selectedUser"
                ></UserAvatar>
                <b>
                  {{ selectedUser.displayName }}
                </b>
              </span>
            </p>
            <v-select
              v-model="selectedUserRole"
              dense
              outlined
              :items="statusList"
              item-text="text"
              item-value="statusName"
            >
            </v-select>
          </v-card-text>
          <!-- END ROLE EDIT -->
          <!-- RESET PASS -->
          <v-card-text v-if="selectedAction === 'resetPass'">
            <p class="my-3">
              Vous allez réinitialiser le mot de passe de <span class="ml-4">
                <UserAvatar
                  size="34px"
                  color="primary"
                  :user="selectedUser"
                ></UserAvatar>
                <b>
                  {{ selectedUser.displayName }}
                </b>
              </span>
            </p>
            <p>
              L'utilisateur reçevra un e-mail avec un nouveau mot de passe.
            </p>
          </v-card-text>
          <!-- END RESET PASS -->
          <v-card-actions class="justify-space-between">
            <v-btn
              text
              @click="dialogslot.value = false"
            >
              Fermer
            </v-btn>
            <v-btn
              v-if="selectedAction === 'delete'"
              color="error"
              outlined
              :disabled="disabled"
              @click="deleteUser"
            >
              Supprimer
            </v-btn>
            <v-btn
              v-if="selectedAction === 'updateRole'"
              color="info"
              outlined
              :disabled="disabled"
              @click="editRole"
            >
              Changer le rôle
            </v-btn>
            <v-btn
              v-if="selectedAction === 'resetPass'"
              color="info"
              outlined
              :disabled="disabled"
              @click="resetPass"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccountMultiple, mdiDotsVertical, mdiPlus, mdiAccount, mdiAccountDetails,
} from '@mdi/js'
import UserAvatar from '@/components/UserAvatar.vue'
import UserRoleChip from './UserRoleChip.vue'

export default {
  components: {
    UserRoleChip,
    UserAvatar,
  },
  data() {
    return {
      dialog: false,
      loading: true,
      disabled: false,
      selectedUser: {},
      selectedAction: '',
      selectedUserRole: '',
      roleFilter: [],
      search: '',
      users: [],
      icons: {
        mdiAccountMultiple, mdiDotsVertical, mdiPlus, mdiAccount, mdiAccountDetails,
      },
      actionMenu: [
        { title: 'Supprimer', action: 'delete' },
        { title: 'Réinitialiser mot de passe', action: 'resetPass' },
        { title: 'Changer rôle', action: 'updateRole' },
      ],
      headers: [
        {
          text: 'Utilisateur',
          align: 'left',
          filterable: true,
          value: 'displayName',
        },
        {
          text: 'E-mail',
          align: 'center',
          value: 'email',
        },
        {
          text: 'Rôle',
          align: 'center',
          value: 'status',
        },
        {
          text: 'Inscrit le',
          align: 'center',
          value: 'created_at',
        },
        {
          text: 'Actions',
          filterable: false,
          align: 'center',
          value: 'actions',
        },
      ],
      statusList: [
        {
          statusName: 'admin',
          text: 'Administrateur',
          color: '#FB8C00',
        },
        {
          statusName: 'tuteur',
          text: 'Tuteur',
          color: '#5C6BC0',
        },
        {
          statusName: 'user',
          text: 'Utilisateur',
          color: 'success',
        },
      ],
    }
  },
  computed: {
    userFilter() {
      if (this.roleFilter.length === 0) {
        return this.users
      }

      return this.users.filter(x => this.roleFilter.includes(x.status))
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchUsers()])
        .then(values => {
          [this.users] = values
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchUsers() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/users-permissions/userlist`)

      return response.data
    },
    doAction(action, item) {
      this.dialog = true
      this.selectedAction = action
      this.selectedUser = item
      this.selectedUserRole = item.status
    },
    deleteUser() {
      this.disabled = true
      this.$http.delete(`${process.env.VUE_APP_API_URL}/users/${this.selectedUser.id}`)
        .then(() => {
          this.users = this.users.filter(x => x.id !== this.selectedUser.id)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Utilisateur supprimé',
            value: true,
          })
          this.dialog = false
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression',
            value: true,
          })
        })
        .finally(
          this.disabled = false,
        )
    },
    editRole() {
      this.disabled = true
      this.$http.put(`${process.env.VUE_APP_API_URL}/users/${this.selectedUser.id}`,
        {
          status: this.selectedUserRole,
        })
        .then(res => {
          const index = this.users.findIndex(x => x.id === this.selectedUser.id)
          if (index !== -1) {
            this.users[index].status = res.data.status
          }
          const matchingText = this.statusList.find(x => x.statusName === res.data.status)
          this.$store.commit('snackMe', {
            color: 'success',
            text: `Le rôle de ${res.data.displayName} est désormais ${matchingText.text}`,
            value: true,
          })
          this.dialog = false
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification de rôle',
            value: true,
          })
        })
        .finally(
          this.disabled = false,
        )
    },
    resetPass() {
      this.disabled = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/forgot-password/`, { email: this.selectedUser.email })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'E-mail envoyé avec succès',
            value: true,
          })
          this.dialog = false
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la réinitialisation du mot de passe',
            value: true,
          })
        })
        .finally(
          this.disabled = false,
        )
    },
  },
}
</script>

<style>

</style>
